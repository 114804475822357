import Spacing from "../../../../src/components/MDX/Spacing";
import PSpacing from "../../../../src/components/MDX/PSpacing";
import PSpacing2 from "../../../../src/components/MDX/PSpacing2";
import Grid2 from "../../../../src/components/MDX/Grid2";
import Grid4 from "../../../../src/components/MDX/Grid4";
import Summary from "../../../../src/components/MDX/Summary";
import Item from "../../../../src/components/MDX/Item";
import PreFooter from "../../../../src/components/MDX/PreFooter";
import FlexColumn from "../../../../src/components/MDX/FlexColumn";
import FlexColumn2 from "../../../../src/components/MDX/FlexColumn2";
import FlexItem from "../../../../src/components/MDX/FlexItem";
import FlexItem2 from "../../../../src/components/MDX/FlexItem2";
import FlexGallery from "../../../../src/components/MDX/FlexGallery";
import FlexGallery2 from "../../../../src/components/MDX/FlexGallery2";
import Social from "../../../../src/components/MDX/Social";
import { FaBehance } from "@react-icons/all-files/fa/FaBehance";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaDribbble } from "@react-icons/all-files/fa/FaDribbble";
import React from "react";
import ReactPlayer from "react-player";
import Video from "../../../../src/components/MDX/Video";
import VideoWeb from "../../../../src/components/MDX/VideoWeb";
import Video2048 from "../../../../src/components/MDX/Video2048";
export default {
  Spacing,
  PSpacing,
  PSpacing2,
  Grid2,
  Grid4,
  Summary,
  Item,
  PreFooter,
  FlexColumn,
  FlexColumn2,
  FlexItem,
  FlexItem2,
  FlexGallery,
  FlexGallery2,
  Social,
  FaBehance,
  FaInstagram,
  FaDribbble,
  React,
  ReactPlayer,
  Video,
  VideoWeb,
  Video2048
};