import Spacing from "../../../../src/components/MDX/Spacing";
import PSpacing from "../../../../src/components/MDX/PSpacing";
import Grid2 from "../../../../src/components/MDX/Grid2";
import Grid4 from "../../../../src/components/MDX/Grid4";
import Summary from "../../../../src/components/MDX/Summary";
import Item from "../../../../src/components/MDX/Item";
import PreFooter from "../../../../src/components/MDX/PreFooter";
import Social from "../../../../src/components/MDX/Social";
import { FaBehance } from "@react-icons/all-files/fa/FaBehance";
import FlexGrid4 from "../../../../src/components/MDX/FlexGrid4";
import FlexItem from "../../../../src/components/MDX/FlexItem";
import FlexColumn from "../../../../src/components/MDX/FlexColumn";
import FlexGallery from "../../../../src/components/MDX/FlexGallery";
import PSpacing2 from "../../../../src/components/MDX/PSpacing2";
import * as React from 'react';
export default {
  Spacing,
  PSpacing,
  Grid2,
  Grid4,
  Summary,
  Item,
  PreFooter,
  Social,
  FaBehance,
  FlexGrid4,
  FlexItem,
  FlexColumn,
  FlexGallery,
  PSpacing2,
  React
};