exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-3-d-media-index-js": () => import("./../../../src/pages/projects/3d-media/index.js" /* webpackChunkName: "component---src-pages-projects-3-d-media-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-ui-ux-design-index-js": () => import("./../../../src/pages/projects/ui-ux-design/index.js" /* webpackChunkName: "component---src-pages-projects-ui-ux-design-index-js" */),
  "component---src-pages-projects-web-design-and-development-index-js": () => import("./../../../src/pages/projects/web-design-and-development/index.js" /* webpackChunkName: "component---src-pages-projects-web-design-and-development-index-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-templates-3-d-media-details-js": () => import("./../../../src/templates/3d-media-details.js" /* webpackChunkName: "component---src-templates-3-d-media-details-js" */),
  "component---src-templates-ui-ux-details-js": () => import("./../../../src/templates/ui-ux-details.js" /* webpackChunkName: "component---src-templates-ui-ux-details-js" */),
  "component---src-templates-web-dnd-details-js": () => import("./../../../src/templates/web-dnd-details.js" /* webpackChunkName: "component---src-templates-web-dnd-details-js" */)
}

