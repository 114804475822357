import Spacing from "../../../../src/components/MDX/Spacing";
import PSpacing from "../../../../src/components/MDX/PSpacing";
import PSpacing2 from "../../../../src/components/MDX/PSpacing2";
import Grid2 from "../../../../src/components/MDX/Grid2";
import Grid4 from "../../../../src/components/MDX/Grid4";
import Summary from "../../../../src/components/MDX/Summary";
import Item from "../../../../src/components/MDX/Item";
import PreFooter from "../../../../src/components/MDX/PreFooter";
import Social from "../../../../src/components/MDX/Social";
import { FaArtstation } from "@react-icons/all-files/fa/FaArtstation";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import React from "react";
import ReactPlayer from "react-player";
import Video from "../../../../src/components/MDX/Video";
export default {
  Spacing,
  PSpacing,
  PSpacing2,
  Grid2,
  Grid4,
  Summary,
  Item,
  PreFooter,
  Social,
  FaArtstation,
  FaInstagram,
  React,
  ReactPlayer,
  Video
};